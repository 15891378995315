import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, first, map, startWith } from 'rxjs';
import { identity } from 'lodash';
import { AuthService } from './auth.service';
import { URLService } from './dynamic-url.service';
import { UserService } from './user.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let ga: any;

export enum FLAGS {
  PROFILES_PAGE = 'profiles_page',
  ENABLE_COMPREHENSION = 'enable_comprehension',
  ENABLE_DOORBELL = 'enable_doorbell',
  ENABLE_REALTIME_RTM = 'enable_realtime_rtm',
  ENABLE_LESSONS = 'enable_lessons',
  USE_WHITEBOARD_FOR_P2P_CALLS = 'use_whiteboard_for_p2p_calls',
  TOPIC_RECOMMENDATIONS = 'topic_recommendations',
  TOPIC_BUILDER = 'topic_builder',
  DISPLAY_FILES_MENU = 'display_files_menu',
  CAPTURE_CAMERA_IMAGES = 'capture_camera_images',
  COURSE_MANAGEMENT = 'course_management',
  DISABLE_MESSAGING = 'disable_messaging',
  ENABLE_GROUP_MESSAGING = 'enable_group_messaging',
  REPORT_WB_BUG = 'report_wb_bug',
  WB_VIDEO_CHAT = 'wb_video_chat',
  WB_PDF_UPLOAD = 'wb_pdf_upload',
  WB_EQUATION_EDITOR = 'wb_equation_editor',
  WB_MESSAGING = 'wb_messaging',
  WB_IMAGE_UPLOAD = 'wb_image_upload',
  WB_VIDEO_UPLOAD = 'wb_video_upload',
  WB_PENCIL_LIBRARY = 'wb_pencil_library',
  WB_BOARD_LOCK = 'wb_board_lock',
  WB_EXPORT = 'wb_export',
  WB_LEADER_MODE = 'wb_leader_mode',
  WB_GRID = 'wb_grid',
  WB_TEXT_BOX = 'wb_text_box',
  WB_SHAPES = 'wb_shapes',
  WB_GADGETS = 'wb_gadgets',
  WB_INVITE = 'wb_invite',
  WB_LEAVE_WHITEBOARD = 'wb_leave_whiteboard',
  WB_RECORD_SESSION = 'wb_record_session',
  WB_TABS = 'wb_tabs',
  WB_HIGHLIGHTER = 'wb_highlighter',
  WB_INVITE_TO_PENCIL = 'wb_invite_to_pencil',
  WB_INVITE_PARTICIPANTS = 'wb_invite_participants',
  WB_AVATAR = 'wb_avatar',
  WB_NAVIGATE_TO_SETTINGS = 'wb_navigate_to_settings',
  WB_UPLOAD_YOUTUBE = 'wb_upload_youtube',
  WB_NO_LONGPOLLING = 'wb_no_longpolling',
  WB_EMOTES = 'wb_emotes',
  WB_CREATE_BOARD_EACH_PARTICIPANT = 'wb_create_board_each_participant',
  WB_BOARD_PREVIEW_ONLY_VISIBLE = 'wb_board_preview_only_visible',
  AUTHORING_DRAFTS = 'authoring_drafts',
  AUTHORING_TABLES = 'authoring_tables',
  SPACE_VIDEO_LOGGING = 'space_video_logging',
  ENABLE_PDF_UPSCALE = 'enable_pdf_upscale',
  ENABLE_BILLING = 'enable_billing',
  ENABLE_SPACE_AI_PRESENCE = 'enable_space_ai_presence',
  SPACES_LANGUAGE_SELECTOR = 'spaces_language_selector',
  USE_STAGED_INSTEAD_OF_UNSUBSCRIBE = 'use_staged_instead_of_unsubscribe',
  AUDIO_RELIABILITY_FIX = 'audio_reliability_fix',
  SPACES_TEXT_EDITOR = 'spaces_text_editor',
  SPACES_FREE_TIME_REFERRAL = 'spaces_free_time_referral',
  SPACES_SHOW_DEVICE_SELECTION_AFTER_CONNECTING = 'spaces_show_device_selection_after_connecting',
  SPACES_PARTICIPANTS_MANAGER = 'spaces_participants_manager',
  PERSISTENT_PERMISSIONS = 'persistent_permissions',
  ENABLE_VIDEO_POP_OUT = 'enable_video_pop_out',
  SPACES_LOCALIZED_LANGUAGES = 'spaces_localized_languages',
  SPACES_USER_CHIMES = 'spaces_user_chimes',
  SPACES_BOARD_DUPLICATE = 'spaces_board_duplicate',
  SPACES_KEEP_NEW_USERS_IN_SPACES_LIST = 'spaces_keep_new_users_in_spaces_list',
  SPACES_LANDING_PREVIEWS = 'spaces_landing_previews',
  ENABLE_SYNC_WITH_WB_SERVER = 'enable_sync_with_wb_server',
  SPACE_VIDEO_CALL_PROVIDER = 'cpaas_provider',
  LOGIN_REDESIGN = 'login_redesign',
  ENABLE_USER_QUESTIONNAIRE = 'enable_user_questionnaire',
  NOTIFICATIONS_BELL = 'notifications_bell',
  SPACES_DISABLE_INCOMING_VIDEOS = 'spaces_disable_incoming_videos',
  SPACES_VIDEO_COUNT = 'spaces_video_count',
  NEW_RIGHT_LAYOUT_VIDEO_UI = 'new_right_layout_video_ui',
  SPACES_LANDING_PAGE_NEW_UI = 'spaces_landing_page_new_ui',
  DEPLOYMENT_ID = 'deployment_id',
  WEB_URL = 'web_url',
  CALENDAR_ADDON_ENABLED = 'calendar_addon_enabled',
  SPACES_ANALYTICS_RECORDING = 'spaces_analytics_recording',
  SPACE_BINARY_CHUNKING = 'space_binary_chunking',
  SPACES_DIRECT_MESSAGING = 'spaces_direct_messaging',
  BREAKOUT_ROOMS = 'breakout_rooms',
  BREAKOUT_ROOMS_BOARDS = 'breakout_rooms_boards',
  SPACES_TILES_LETTERBOXING = 'spaces_tile_letterboxing',
  DYNAMIC_RIGHT_VIEW = 'dynamic_right_view',
  PROPAGATE_POINTER_LOCATIONS_ON_JOIN = 'propagate_pointer_locations_on_join',
  SPACES_TRANSCRIPTION = 'spaces_transcription',
  ENABLE_REDIS_PRESENCE = 'enable_redis_presence',
  WEBSOCKET_WEB_WORKER = 'websocket_web_worker',
  ENABLE_MOBILE_CHAT = 'enable_mobile_chat',
  EDIT_BOARD_MOBILE = 'edit_board_mobile',
  FABRIC_OBJECT_CACHING = 'fabric_object_caching',
  CALL_TRACKS_V2 = 'call_tracks_v2',
  SPACES_DEV_TOOLS = 'spaces-dev-tools',
  SPACES_PRIVATE_BOARDS = 'spaces_private_boards',
  SHOW_API_KEY_GENERATION = 'show_api_key_generation',
  ENABLE_EVENTS_FILTERS = 'enable_events_filters',
  ENABLE_REPORTS_FILTERS = 'enable_reports_filters',
  ENABLE_EVENTS_CA = 'enable_events_ca',
  ENABLE_SETTINGS_CA = 'enable_settings_ca',
  ENABLE_SPACES_CA = 'enable_spaces_ca',
  ENABLE_PROFILES_CA = 'enable_profiles_ca',
  ENABLE_SPACES_MATH_MANIPULATIVES = 'enable_spaces_math_manipulatives',
  ENABLE_SPACES_COMMENTS = 'enable_spaces_comments',
  SPACES_STICKY_NOTES = 'spaces_sticky_notes',
  MARIO = 'mario',
  CHAT_GPT = 'chat_gpt',
  MOBILE_ATTACHMENTS = 'mobile_attachments',
  SESSION_ANALYTICS_UI = 'session_analytics_ui',
  SPACES_REORDER_BOARDS = 'spaces_reorder_boards',
  SPACE_REALTIME_TEXT_UPDATE = 'space_realtime_text_update',
  SPACES_VIRTUAL_BACKGROUNDS = 'spaces_virtual_backgrounds',
  SPACES_OBJECT_LOCK = 'spaces_object_lock',
  PICASSO = 'picasso',
  SPACES_TOOLBAR_SHORTCUTS = 'spaces_toolbar_shortcuts',
  GESTURES_WEB_WORKER = 'gestures_web_worker',
  ENABLE_FILE_PDF_CONVERSION = 'enable_file_pdf_conversion',
  SHOW_MULTI_TABS_WARNING = 'show_multi_tabs_warning',
  LOGIN_PROVIDERS = 'login_providers',
  ENABLE_DAILY_NOISE_CANCELLATION = 'enable_daily_noise_cancellation',
  SHOW_REFER_FRIEND = 'show_refer_friend',
  SPACES_BOARDS_BACKGROUNDS = 'spaces_boards_backgrounds',
  SPACES_BOARD_FOLDERS = 'spaces_board_folders',
  BOARD_CHANGE_CONFIRMATION_DIALOG = 'board_change_confirmation_dialog',
  TEST_CLONE_SPACES = 'test_clone_spaces',
  ENABLE_AUDIO_MUSIC_MODE = 'enable_audio_music_mode',
  ENABLE_CHATGPT = 'enable_chatgpt',
  ENABLE_TRANSCRIPTION_LOGGING = 'enable_transcription_logging',
  SPEECH_DURATION_COLUMN = 'speech_duration_column',
  APPS_PRIVACY_SWITCH = 'apps_privacy_switch',
  USER_INFO_V2 = 'user_info_v2',
  WEB_VIEWER_SCREENSHOT = 'web_viewer_screenshot',
  APPS_INTERACTIONS_LOCK_SWITCH = 'apps_interactions_lock_switch',
  APPS_AVAILABILITY_SWITCH = 'apps_availability_switch',
  CHAT_GPT_SCREENSHOT = 'chat_gpt_screenshot',
  SCREEN_SHARE_SCREENSHOT = 'screen_share_screenshot',
  CLOUD_RECORDING = 'cloud_recording',
  ENABLE_SCHEDULE = 'enable_schedule',
  PROJECT_SAM = 'project_sam',
  WEAK_NETWORK_DETECTION = 'weak_network_detection',
  PERFORMANCE_INTERVENTION_CPU = 'performance_intervention_cpu',
  PERFORMANCE_INTERVENTION_MEMORY = 'performance_intervention_memory',
  REDIS_FRAME_PRESENCE = 'redis_frame_presence',
  ENABLE_LOADING_TIPS = 'enable_loading_tips',
  SUPRESS_NOTIFICATIONS = 'supress_notifications',
  DISABLE_APPS_ON_MOBILE = 'disable_apps_on_mobile',
  SPACES_EXPERIENCE_RATING = 'spaces_experience_rating',
  USE_FAYE = 'use_faye',
  ZOOMED_OUT_APPS_OVERLAY = 'zoomed_out_apps_overlay',
  SHOW_FORCE_CPU_TIERS = 'show_force_cpu_tiers',
  EXPORT_BOARDS = 'export_boards',
  INACTIVITY_NOTIFICATIONS = 'inactivity_notifications',
  PROGRESS_INDICATOR_IMAGE_CANVAS = 'progress_indicator_image_canvas',
  SMALL_SCREEN_OVERLAY = 'small_screen_overlay',
  ONLY_ONE_WEBVIEWER_TIER_2 = 'only_one_webviewer_tier_2',
  WB_TOP_TOOLBAR_V2 = 'wb_top_toolbar_v2',
  DISABLE_RESOURCE_SIGNING = 'disable_resource_signing',
  LIMIT_FABRIC_OBJECTS_ON_BOARD = 'limit_fabric_objects_on_board',
  REQUEST_ACCESS_ENABLED = 'request_access_enabled',
  PENCIL_AI_ASSISTANT = 'pencil_ai_assistant',
  ENABLE_YJS_FABRIC_JSON = 'enable_yjs_fabric_json',
  ENABLE_ANONYMOUS_LOGIN = 'enable_anonymous_login',
  NOISE_CANCELLATION_DEFAULT_STATE = 'noise_cancellation_default_state',
  ENABLE_REDIS_USER_TEMPORARY_METADATA = 'enable_redis_user_temporary_metadata',
  LIVE_NOTIFICATION_SPEAKING_ASSISTANT = 'live_notification_speaking_assistant',
  LIVE_NOTIFICATION_SPEAKING_DURATION = 'live_notification_speaking_duration',
  ENABLE_SPACES_FILTERS = 'enable_space_filters',
  ENABLE_SPACES_TEMPLATES = 'enable_spaces_templates',
  SPACE_AUTO_END_CALL = 'space_auto_end_call',
  ENABLE_MULTIPLE_JOIN_CALL_FROM_THE_SAME_ACCOUNT = 'enable_multiple_join_call_from_the_same_account',
  SCREEN_SHARE_SETTINGS = 'screen_share_settings',
  CHROME_CANVAS_BUG_FIX = 'chrome_canvas_bug_fix',
  ENABLE_DIRECT_CLIENT_DOWNLOAD_FROM_GCS = 'enable_direct_client_download_from_gcs',
  POINTER_EVENTS_FABRIC = 'pointer_events_fabric',
  ENABLE_INSTITUTION_BILLING_SETTINGS = 'enable_institution_billing_settings',
  ENABLE_AI_ASSISTANT = 'enable_ai_assistant',
  WHITEBOARD_URL = 'whiteboard_url',
  ENABLE_NETWORK_INDICATOR = 'enable_network_indicator',
  ENABLE_NSFW_DETECTION = 'enable_nsfw_detection',
}

const FLAGS_CSV_VARIABLES: { [key: string]: string[] } = {
  [FLAGS.MARIO]: ['enabled_mario_apps'],
  [FLAGS.ENABLE_LOADING_TIPS]: ['tips_items'],
};

@Injectable({
  providedIn: 'root',
})
/*
Provide access to current feature flags and experiments.
*/
export class FlagsService {
  featureFlags: Record<string, boolean> = {};
  featureFlagsVariables: Record<
    string,
    Record<string, string | number | boolean | Record<string, boolean>>
  > = {};
  customFeatureFlags?: Record<string, boolean>;
  featureFlagsChanged = new EventEmitter();
  isCustomFlagsEnabled = new BehaviorSubject(false);

  constructor(
    private userService: UserService,
    private urlService: URLService,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.logOutEmitter.subscribe(() => {
      this.getFlags();
    });
  }

  getFlags(): Promise<boolean> {
    return new Promise((resolve) => {
      const flagsJson = localStorage.getItem('feature-flags');
      this.isCustomFlagsEnabled.next(!!flagsJson);
      if (flagsJson) {
        try {
          // Ensure that if there are some new flags added and you have custom flags they show up
          // when you open the custom flags UI
          const defaultFlags = Object.values(FLAGS).reduce((prev, cur) => {
            prev[cur] = false;
            return prev;
          }, {});
          this.customFeatureFlags = { ...defaultFlags, ...JSON.parse(flagsJson) };
        } catch (ex) {
          this.customFeatureFlags = undefined;
          console.error(ex);
        }
      }

      this.userService.user.subscribe((user) => {
        if (user) {
          this.featureFlags = user?.user?.feature_flags ?? {};
          this.featureFlagsVariables =
            this.processSplitFlagCSVVariables(user?.user?.feature_flag_variables) ?? {};
          this.featureFlagsChanged.emit();
          // To set a custom diemnsion in GA, use the sendGTMData
          // method in app.component.ts to add new fields to the
          // GTM data layer, then gop to GTM to add the variables
          // and their mapping to custom diemnsions in GA.
          resolve(!!user);
        } else {
          this.getAnonymousFlags()
            .pipe(first())
            .subscribe({
              next: (res) => {
                if (res) {
                  this.featureFlags = res.flags;
                  this.featureFlagsVariables = this.processSplitFlagCSVVariables(res.flagVariables);
                  this.featureFlagsChanged.emit();
                  resolve(true);
                }
              },
              error: (res) => {
                console.log(res);
              },
            });
        }
      });
    });
  }
  /*
   * @emitInitSeparately: a flag to emit the default value whether
   * the value fetched from the API is the same or not
   */
  featureFlagChanged(flag: FLAGS, emitInitSeparately = false): Observable<boolean> {
    return this.featureFlagsChanged.asObservable().pipe(
      !emitInitSeparately ? startWith(() => this.isFlagEnabled(flag)) : identity,
      map(() => this.isFlagEnabled(flag)),
      distinctUntilChanged(),
      emitInitSeparately ? startWith(this.isFlagEnabled(flag)) : identity,
    );
  }

  isFlagEnabled(flag: FLAGS): boolean {
    const value = this.customFeatureFlags
      ? this.customFeatureFlags[flag]
      : this.featureFlags[flag] ?? false;
    return value;
  }

  processSplitFlagCSVVariables(
    featureFlagsVariables: any,
  ): Record<string, Record<string, string | number | boolean | Record<string, boolean>>> {
    if (!featureFlagsVariables) {
      return {};
    }
    const outputFlags = JSON.parse(JSON.stringify(featureFlagsVariables));
    Object.keys(FLAGS_CSV_VARIABLES).forEach((key) => {
      if (featureFlagsVariables[key]) {
        FLAGS_CSV_VARIABLES[key].forEach((value) => {
          let splitRecord = {};
          if (featureFlagsVariables[key][value]) {
            splitRecord = (featureFlagsVariables[key][value] as string)
              .split(',')
              .reduce((recordMap, currentItem) => ({ ...recordMap, [currentItem]: true }), {});
          }
          outputFlags[key][value] = splitRecord;
        });
      }
    });
    return outputFlags;
  }

  /**
   * Overwrites the user feature flags with custom one.
   * This method uses local storage, so clearing cache will clear the custom flags
   * and revert back to using the original user feature flags coming from the BE.
   * @param flags The flags to overwrite
   */
  overwriteFlags(flags: Record<string, boolean>) {
    this.customFeatureFlags = Object.assign({}, flags);
    localStorage.setItem('feature-flags', JSON.stringify(this.customFeatureFlags));
  }

  /**
   * Resets the feature flags to the original user flags.
   */
  resetFlags() {
    this.customFeatureFlags = undefined;
    localStorage.removeItem('feature-flags');
  }

  getAnonymousFlags(): any {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/feature_flags`);
  }
}
