import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';

export enum CustomUrlStorageKeys {
  ApiServerUrlKey = 'serverurl',
  WbServerUrlKey = 'wbserverurl',
}

@Injectable({
  providedIn: 'root',
})
export class URLService {
  private localStorageKeys = [
    CustomUrlStorageKeys.ApiServerUrlKey,
    CustomUrlStorageKeys.WbServerUrlKey,
  ];

  private defaulUrls = {
    [CustomUrlStorageKeys.ApiServerUrlKey]: environment.serverURL,
    [CustomUrlStorageKeys.WbServerUrlKey]: environment.whiteboardServer,
  };

  public customServerUrls = new BehaviorSubject<{ [key: string]: string }>(this.getAllCustomUrls());

  getDynamicUrl(): string {
    return this.getDynamicUrlByKey(CustomUrlStorageKeys.ApiServerUrlKey);
  }

  getCustomUrl(key: CustomUrlStorageKeys): string | undefined {
    return localStorage.getItem(key)?.toString();
  }

  getDefaultUrl(key: CustomUrlStorageKeys): string {
    return this.defaulUrls[key];
  }

  setCustomUrl(url: string, key: CustomUrlStorageKeys) {
    this.storeCustomUrlInStorage(url, key);
    this.customServerUrls.next(this.getAllCustomUrls());
  }

  resetUrl(key: CustomUrlStorageKeys) {
    localStorage.removeItem(key);
  }

  resetUrls() {
    this.localStorageKeys.forEach((key) => this.resetUrl(key));
  }

  private getDynamicUrlByKey(key: CustomUrlStorageKeys): string {
    this.updateCustomFromQueryString(key);
    const savedUrl = this.getCustomUrl(key);
    return savedUrl ?? this.getDefaultUrl(key);
  }

  private updateCustomFromQueryString(key: CustomUrlStorageKeys): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(key)) {
      const url = urlParams.get(key);
      if (!url?.trim()) {
        // This is a way to manually reset url.
        this.resetUrls();
        return;
      }
      // Make urls added to the address bar sticky
      this.storeCustomUrlInStorage(url, key);
    }
  }

  private storeCustomUrlInStorage(url: string, key: CustomUrlStorageKeys) {
    const strippedTrailingSlashUrl = url.replace(/\/+$/, '');
    const httpCheckedUrl =
      strippedTrailingSlashUrl.search(/http/) !== -1
        ? strippedTrailingSlashUrl
        : `https://${strippedTrailingSlashUrl}`;
    localStorage.setItem(key, httpCheckedUrl);
  }

  private getAllCustomUrls(): Partial<Record<CustomUrlStorageKeys, string>> {
    const customUrls: Partial<Record<CustomUrlStorageKeys, string>> = {};

    this.localStorageKeys.forEach((key) => {
      const url = this.getDynamicUrlByKey(key);
      if (url !== this.defaulUrls[key]) {
        customUrls[key] = url;
      }
    });
    return customUrls;
  }
}
