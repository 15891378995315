import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CustomUrlStorageKeys, URLService } from './dynamic-url.service';
import { FLAGS, FlagsService } from './flags.service';

@Injectable({
  providedIn: 'root',
})
export class WhiteboardUrlService {
  private readonly SOCKET_IO_DEFAULT_PATH = '/socket.io/';

  constructor(private flagsService: FlagsService, private urlService: URLService) {}

  getFullWhiteboardUrl(): string {
    return this._getWhiteboardUrl();
  }

  getWhiteboardUrl(): string {
    const urlString = this._getWhiteboardUrl();
    const url = new URL(urlString);
    return `${url.protocol}//${url.hostname}`;
  }

  getWhiteboardPath(): string {
    const urlString = this._getWhiteboardUrl();
    const url = new URL(urlString);
    let wbServerPath = url.pathname;
    if (wbServerPath === '' || wbServerPath === '/') {
      wbServerPath = this.SOCKET_IO_DEFAULT_PATH;
    } else if (!wbServerPath.endsWith(this.SOCKET_IO_DEFAULT_PATH)) {
      wbServerPath += this.SOCKET_IO_DEFAULT_PATH;
    }
    return wbServerPath;
  }

  /**
   * Fetch the whiteboard url in the following order:
   * 1. Custom url set in the admin settings page
   * 2. Optimizely flag url
   * 3. Default url
   * @returns the whiteboard url
   */
  private _getWhiteboardUrl() {
    const customUrl = this.urlService.getCustomUrl(CustomUrlStorageKeys.WbServerUrlKey);

    if (customUrl) {
      return customUrl;
    }

    const optimizelyUrl = this.getOptimizelyWhiteboardUrl();

    if (optimizelyUrl) {
      return optimizelyUrl;
    }

    return this.urlService.getDefaultUrl(CustomUrlStorageKeys.WbServerUrlKey);
  }

  private getOptimizelyWhiteboardUrl(): string | undefined {
    if (!this.flagsService.isFlagEnabled(FLAGS.WHITEBOARD_URL)) {
      return undefined;
    }

    const whiteboardUrl = this.flagsService.featureFlagsVariables[FLAGS.WHITEBOARD_URL]?.whiteboard_url;

    if (!_.isString(whiteboardUrl)) {
      return undefined;
    }

    // By default unitized variables in optimizely are set to an empty string
    if (whiteboardUrl.trim().length === 0) {
      return undefined;
    }

    return whiteboardUrl;
  }
}
