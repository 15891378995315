import { Pipe, PipeTransform } from '@angular/core';
import { IndividualUserOverview, User } from '../models/user';
import { AclService, Feature } from '../services/acl.service';

@Pipe({
  name: 'has',
})
export class HasPipe implements PipeTransform {
  constructor(private aclService: AclService) {}

  transform(user: User | IndividualUserOverview | undefined, feature: Feature): boolean {
    if (!user) {
      return false;
    }
    return this.aclService.isAllowed(user, feature);
  }
}

@Pipe({
  name: 'isStudent',
})
export class IsStudentPipe implements PipeTransform {
  constructor(private aclService: AclService) {}

  transform(user: User | IndividualUserOverview): boolean {
    return this.aclService.isStudent(user);
  }
}

@Pipe({
  name: 'isTeacher',
})
export class IsTeacherPipe implements PipeTransform {
  constructor(private aclService: AclService) {}

  transform(user: User | IndividualUserOverview): boolean {
    return this.aclService.isTeacher(user);
  }
}

@Pipe({
  name: 'isAdmin',
})
export class IsAdminPipe implements PipeTransform {
  constructor(private aclService: AclService) {}

  transform(user: User | IndividualUserOverview): boolean {
    return this.aclService.isAdmin(user);
  }
}

@Pipe({
  name: 'isSuperAdmin',
})
export class IsSuperAdminPipe implements PipeTransform {
  constructor(private aclService: AclService) {}

  transform(user?: User | IndividualUserOverview): boolean {
    return user ? this.aclService.isSuperAdmin(user) : false;
  }
}
