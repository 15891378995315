import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Site, User, UserTypes, roleValueIDs } from '../models/user';
import { URLService } from './dynamic-url.service';

export enum Feature {
  Type = 'Type',
  Status = 'Status',
  Explanation = 'Explanation',
  Annotation = 'Annotation',
  Difficulty = 'Difficulty',
  GradeRange = 'GradeRange',
  Quality = 'Quality',
  QuestionContents = 'QuestionContents',
  PDContent = 'PDContent',
  Source = 'Source',
  Author = 'Author',
  Language = 'Language',
  ExplanationAuthor = 'ExplanationAuthor',
  Visibility = 'Visibility',
  Practice = 'Practice',
  PhaseOfLearning = 'PhaseOfLearning',
  Manipulatives = 'Manipulatives',
  Interests = 'Interests',
  CognitiveSkills = 'CognitiveSkills',
  Liked = 'Liked',
  Bookmarked = 'Bookmarked',
  Ingestion = 'Ingestion',
  Images = 'Images',
  HasTable = 'HasTable',
  HasImage = 'HasImage',

  // BE Features
  Migrate = 'Migrate',
  PDProfile = 'PDProfile',
  Impersonate = 'Impersonate',
  AdminPage = 'AdminPage',
  ContentAnalytics = 'ContentAnalytics',
  PracticeAnalytics = 'PracticeAnalytics',

  // Shared Features
  CustomFeatures = 'CustomFeatures',
  ProfilesPage = 'ProfilesPage',

  // questions
  create_question = 'create_question',
  edit_question = 'edit_question',
  delete_question = 'delete_question',
  view_question = 'view_question',
  print_question = 'print_question',
  change_question_privacy = 'change_question_privacy',
  view_details_pane = 'view_details_pane',
  view_relateds = 'view_relateds',

  // notes
  create_note = 'create_note',
  edit_note = 'edit_note',
  delete_note = 'delete_note',
  view_note = 'view_note',
  print_note = 'print_note',

  // Sets
  create_set = 'create_set',
  edit_set = 'edit_set',
  delete_set = 'delete_set',
  view_set = 'view_set',
  print_set = 'print_set',

  // Lessons
  create_lesson = 'create_lesson',
  edit_lesson = 'edit_lesson',
  delete_lesson = 'delete_lesson',
  view_lesson = 'view_lesson',

  // homework
  create_homework = 'create_homework',
  assign_homework = 'assign_homework',
  grade_homework = 'grade_homework',
  view_homework = 'view_homework',

  // whiteboard
  create_whiteboard = 'create_whiteboard',
  import_resources_in_whiteboard = 'import_resources_in_whiteboard',
  import_content_in_whiteboard = 'import_content_in_whiteboard',
  edit_whiteboard = 'edit_whiteboard',

  // sessions
  view_schedule_page = 'view_schedule_page',
  schedule_event = 'schedule_event',
  edit_institution_event = 'edit_institution_event',
  delete_institution_event = 'delete_institution_event',
  view_institution_events = 'view_institution_events',
  edit_site_event = 'edit_site_event',
  delete_site_event = 'delete_site_event',
  view_site_events = 'view_site_events',
  join_event_lobby = 'join_event_lobby',
  join_event_session = 'join_event_session',
  end_event_session = 'end_event_session',
  leave_event_session = 'leave_event_session',
  record_event_session = 'record_event_session',
  observe_event_session = 'observe_event_session',
  share_session = 'share_session',
  access_sites = 'access_sites',
  web_viewer = 'web_viewer',

  broadcast_in_session = 'broadcast_in_session',
  rtc_in_session = 'rtc_in_session',
  chat_in_session = 'chat_in_session',

  // resource
  upload_resource = 'upload_resource',
  edit_resource = 'edit_resource',
  delete_resource = 'delete_resource',
  view_resource = 'view_resource',

  // courses
  create_course = 'create_course',
  edit_course = 'edit_course',
  delete_course = 'delete_course',
  view_course = 'view_course',

  // user
  create_user = 'create_user',
  edit_user = 'edit_user',
  delete_user = 'delete_user',
  view_user_profiles = 'view_user_profiles',
  create_admin_user = 'create_admin_user',
  edit_admin_user = 'edit_admin_user',
  delete_admin_user = 'delete_admin_user',
  suspend_user = 'suspend_user',

  add_user_to_course = 'add_user_to_course',
  remove_user_from_course = 'remove_user_from_course',

  // institution
  edit_institution = 'edit_institution',

  // communication
  comment = 'comment',
  chat = 'chat',
  rtc = 'rtc',

  // special features
  isGuest = 'isGuest',
  isAnonymous = 'isAnonymous',
  isStudent = 'isStudent',
  isTeacher = 'isTeacher',
  isAdmin = 'isAdmin',
  isSuperAdmin = 'isSuperAdmin',
  isSiteAdmin = 'isSiteAdmin',
  isParent = 'isParent',
  isRootAdmin = 'isRootAdmin', // PNCL ADMINISTRATOR ONLY

  // Topic Builder
  create_topics = 'create_topics',
  edit_topics = 'edit_topics',

  // api
  view_token = 'view_token',
}

export enum Personas {
  Administrator = 'Administrator',
  Anonymous = 'Anonymous',
  CoachManager = 'CoachManager',
  Teacher = 'Teacher',
  Student = 'Student',
  DataentryReviewer = 'DataentryReviewer',
  DataentryAgent = 'DataentryAgent',
  InstitutionAdmin = 'InstitutionAdmin',
  SiteAdmin = 'SiteAdmin',
  TeachingAssistant = 'TeachingAssistant',
  Parent = 'Parent',
  Guest = 'Guest',
}

@Injectable({
  providedIn: 'root',
})
export class AclService {
  // Maps user features for quickly checking enabled features.
  userFeaturesMap: Map<string, Map<Feature, boolean>> = new Map();
  allFeatures = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private urlService: URLService) {}

  isAllowed(user: UserTypes, feature: Feature): boolean {
    if (!user?.enabled_features || !user?.enabled_features?.length) {
      return false;
    }

    if (user._id) {
      const featureMap: Map<Feature, boolean> = this.featureMapForUser(user);
      return !!featureMap[feature];
    }

    return !!user.enabled_features?.includes(feature);
  }
  isAnyFeatureAllowed(user: UserTypes, features: Feature[]) {
    return features.some((feature) => this.isAllowed(user, feature));
  }

  isStudent(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isStudent);
  }

  isTeacher(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isTeacher);
  }

  isAdmin(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isAdmin);
  }

  isGuest(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isGuest) || !user.institution;
  }

  isAnonymous(user: UserTypes): boolean {
    return (
      this.isAllowed(user, Feature.isAnonymous) || !!user.personas?.includes(Personas.Anonymous)
    );
  }

  isSiteAdmin(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isSiteAdmin);
  }
  isAdminOrSiteAdmin(user: UserTypes): boolean {
    return this.isSiteAdmin(user) || this.isAdmin(user);
  }

  isSuperAdmin(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isSuperAdmin);
  }

  isParent(user: UserTypes): boolean {
    return this.isAllowed(user, Feature.isParent);
  }

  isPencilAdmin(user: UserTypes): boolean {
    return !!user.personas?.includes(Personas.Administrator);
  }

  getUserSites(user: User): Site[] | undefined {
    return this.isAdmin(user) ? user.institution?.sites : user.sites;
  }

  getPersonas(): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/permissions/personas`);
  }

  getFeatures(): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/permissions/features`);
  }

  getPersonaToFeatures(persona: string): Observable<any> {
    return this.http.get(
      `${this.urlService.getDynamicUrl()}/tutor/permissions/features/${persona}`,
    );
  }

  featureMapForUser(user: UserTypes): Map<Feature, boolean> {
    if (!this.userFeaturesMap[user._id] && user.enabled_features) {
      this.userFeaturesMap[user._id] = user.enabled_features.reduce(
        (map, feature) => ((map[feature] = true), map),
        new Map(),
      );
    }
    return this.userFeaturesMap[user._id];
  }

  getAllFeatures(): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/tutor/permissions/all`);
  }

  checkUserCanCreateSpace(user: User) {
    if (!user.institution && user.info?.role == roleValueIDs.student) {
      // Guest students not allowed to create Spaces
      return false;
    }
    if (user.institution && this.isStudent(user)) {
      // Institution students not allowed to create Spaces
      return false;
    }
    if (
      user.institution &&
      user.institution.settings?.preventTeachersfromCreatingSpaces &&
      !(this.isAdmin(user) || this.isSuperAdmin(user))
    ) {
      // Hide Create Space if not allowed at institution level
      return false;
    }
    return this.isAllowed(user, Feature.create_whiteboard);
  }
}
